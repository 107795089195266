import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"
import ImageDownloadSocial from "../../../components/ImageDownloadSocial"


const IndexPage = () => {
    const root = "/img/download/conference"

    return (
        <Layout pageTitle="Conference & Awards - Standard Graphics">
            <Row>
                <Col>
                    <Link to="/conference">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                    <p className="para">
                        Use these images to promote and share the Women's
                        Business Conference & Awards on social media.
                    </p>
                </Col>
            </Row>
            
            <ImageDownload
                root={root}
                name="General Promo"
                filename="conference-general-promo"
                text="Click for a selection of images designed to promote the Women's Business Conference & Awards across the globe. You can also find some general marketing copy."
                squareHref="https://www.canva.com/design/DAFnNbG93gU/P6gcq7A1wvl4DXrZNVaQZw/view?utm_content=DAFnNbG93gU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAE2FbUwVbM/jwVqnKK_eHbxYP-_uBVQrQ/view?utm_content=DAE2FbUwVbM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/1H8r8KhTSL-vtNaY0j8ka4W88880IcQh7Bhd9rfFH9Kc/edit?usp=sharing"
            />
            <ImageDownload
                root={root}
                name="Conference Highlights"
                filename="conference-highlights"
                text="Click for a selction of our Conference Highlights, highlighting the mission of the Women's Business Conference & Awards. You can also find some general marketing copy."
                squareHref="https://www.canva.com/design/DAFn24zbNJ0/PRMLUEgkk3FS_Q3Mco-svQ/view?utm_content=DAFn24zbNJ0&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAE1UzsZs1s/wu6AHeGy6FtkWJsfDQVpWA/view?utm_content=DAE1UzsZs1s&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/1H8r8KhTSL-vtNaY0j8ka4W88880IcQh7Bhd9rfFH9Kc/edit?usp=sharing"
            />
            <ImageDownloadSocial
                root={root}
                name="Social Media Banners"
                filename="conference-social"
                text="Download banners to use on your social media profiles."
                linkedinCompany="https://www.canva.com/design/DAEhEOPRVYI/XzqKuBxKdHWDE5cHv8h5eA/view?utm_content=DAEhEOPRVYI&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                linkedinProfile="https://www.canva.com/design/DAE2WjnJ8bI/yy-JxCNHlvcHN7rhcjDYKg/view?utm_content=DAE2WjnJ8bI&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                fbgroup="https://www.canva.com/design/DAEiHN04qLA/tEznrSlVXZg11xjOjCxJsg/view?utm_content=DAEiHN04qLA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                fbpage="https://www.canva.com/design/DAEiHMO8CU8/KMVaLVnmQ5mdWy-9nFbO0A/view?utm_content=DAEiHMO8CU8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                fbprofile="https://www.canva.com/design/DAE1-giO6OU/RM2hjBjoDyqNuftxrtDn_A/view?utm_content=DAE1-giO6OU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                twitter="https://www.canva.com/design/DAEiHEasfXw/bwfY_LN8ev9VHpTV9rnazQ/view?utm_content=DAEiHEasfXw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                            />
        </Layout>
    )
}

export default IndexPage
